<template>
  <div id="radioButtonField" v-if="isVisible">
    <div class="field">
      <label class="label">
        {{ field.label }}
        <span v-show="isRequired" class="has-text-danger">*</span>
        <span
          v-show="field.helpBlockText"
          class="icon right tooltip is-tooltip-left"
          :data-tooltip="field.helpBlockText"
        >
          <icon :icon="['fas', 'question-circle']" />
        </span>
      </label>
      <div v-if="isPreview" class="control">
        <input
          class="input"
          type="text"
          readonly
          :value="fieldValue"
        />
      </div>
      <div
        v-else
        :class="{ 'is-danger': !valid }"
        class="control"
        v-for="(option, index) in activeOptions"
        :key="index"
      >
        <label class="radio">
          <input
            type="radio"
            :required="isRequired"
            :disabled="isDisabled || isPreview"
            v-model="fieldValue"
            :value="option.value || `option${index + 1}`"
            @change="emitChange($event)"
          />
          {{ option.label }}
        </label>
      </div>
      <p class="help" :class="{ 'is-danger': !valid }" v-show="!valid">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import FieldsMixin from '@/components/mixins/FieldsMixin';

export default {
  name: 'RadioButtonField',
  mixins: [FieldsMixin],
  props: {
    field: {
      type: Object
    }
  },
  inject: ['isFieldRequired'],
  data() {
    return {
      activeOptions: this.field.options,
      valid: true,
      errorMessage: ''
    };
  },
  computed: {
    fieldValue: {
      get() {
        if (
          this.field.selectedOption &&
          this.field.selectedOption.hasOwnProperty('value')
        ) {
          this.field.selectedOption = this.field.selectedOption.value;
        }
        return this.field.selectedOption;
      },
      set(value) {
        this.field.selectedOption = value;
      }
    },
    isRequired() {
      return this.isFieldRequired(this.field);
    }
  },
  methods: {
    validate() {
      if (this.isRequired && this.fieldValue === 'default') {
        this.valid = false;
        this.errorMessage = 'Please, select one.';
      } else {
        this.valid = true;
      }
      return this.valid;
    }
  }
};
</script>

<style lang="scss" scoped>
#radioButtonField {
  @extend %fieldContainer;
  @extend %selectInput;
}
</style>
